import React from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
// import cover from "../../images/about/difference-header.jpg";
// import image1 from "../../images/about/diff-body.jpg";
import Newsletter from "../../components/Newsletter";

class AboutDifference extends React.Component {
  render() {
    return (
      <Layout bodyClass="page-aboutDifference common">
        <SEO title="Our Difference" />
        <div className="cover-container">
          <div className="cover-img">
            <Img
              fluid={this.props.data.cover.childImageSharp.fluid}
              className="img-fluid"
              alt="Cover Image"
            />
          </div>
          <div className="cover-content">
            <div>
              <h1 className="hero-title">Our Difference</h1>
            </div>
          </div>
        </div>
        <div className="para-one text-center">
          <div className="container">
            <div className="wrapper-m title-5 color-primary text-uppercase">
              {" "}
              <h2 className="p-5">
                {" "}
                The secret to what MAKES SPKL beef so delicious isn't really a
                secret at all, it's walking around the Australian countryside in
                plain daylight.{" "}
              </h2>
            </div>
            <div className="bar-wrapper">
              {" "}
              <div className="bar" />
            </div>
            <div className="row d-flex align-items-center justify-content-center flex-column">
              <div className="col-md-6">
                <Img
                  fluid={this.props.data.aboutImg.childImageSharp.fluid}
                  className="img-fluid mb-4"
                  alt="Cover Image"
                />
              </div>
              <div className="col-md-6">
                <p className="color-white">
                  Renowned for their relaxed and friendly temperament and much
                  more resistant to drought, heat and cold than other breeds,
                  Speckle Park cattle thrive in all conditions. They are
                  Producing the best, most consistently tender beef year round.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Newsletter />
      </Layout>
    );
  }
}

export default AboutDifference;

export const query = graphql`
  query {
    cover: file(relativePath: { eq: "difference-header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutImg: file(relativePath: { eq: "diff-body.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
